setTimeout(function(){
    $('#dwatro-login-form').on('submit', function(e){
        e.preventDefault();
        dwatro_login();
    });
}, 1000)


function dwatro_login(){
    var int;
    $.ajax({
        url: ajax_obj.ajaxurl,
        method: 'post',
        data: {
            'action': 'dwatro_login',
            'data': $('#dwatro-login-form').serialize(),
            'nonce': ajax_obj.nonce
        },
        beforeSend: function (errorThrown) {
            $('.loader-button').html('.');
            $('.login-result').html('');
            int = setInterval(function(){
                $('.loader-button').html($('.loader-button').html() + '.');
            }, 1000)
        },
        success: function (data) {
            clearInterval(int);
            $('.loader-button').html($('.loader-button').attr('data-login'));
            try{
                var result = $.parseJSON(data);
                if(result['status'] == 'error'){
                    $('.login-result').html(result['message']);
                }
                else{
                    window.location.href = result['redirectUri'];
                }
            }
            catch(e){
                alert('Wystąpił jakiś błąd, spróbuj ponownie później.');
            }
        },
        error: function (errorThrown) {
            console.log(errorThrown);
        }
    });
}