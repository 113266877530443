$(document).ready(function(){
    if($('.berocket_single_filter_widget').length > 0){
        populate_dwatro_filters();
    }
    $('[name="orderby"]').attr('id', 'field');
    $('[name="orderby"]').addClass('products_sort_select_field w-select');
})
$(document).on('berocket_ajax_products_loaded', function(event, response) {
    $('[name="orderby"]').attr('id', 'field');
    $('[name="orderby"]').addClass('products_sort_select_field w-select');
});
$(document).on('berocket_products_list_updated', function(event, response) {
    $('[name="orderby"]').attr('id', 'field');
    $('[name="orderby"]').addClass('products_sort_select_field w-select');
});
$(document).on('berocket_products_filtered', function(event, response) {
    $('[name="orderby"]').attr('id', 'field');
    $('[name="orderby"]').addClass('products_sort_select_field w-select');
});
function populate_dwatro_filters(){
    $('.products_filters_types_list .filters_dropdown').remove();
    $('.berocket_single_filter_widget').each(function(){
        var fid = $(this).attr('data-id'), title = $(this).find('.bapf_head h3').html();
        var vis = false;
        $('.products_filters_types_list').append('<div class="filters_dropdown" data-id="'+fid+'" />');
        $('.filters_dropdown[data-id="'+fid+'"]').append('<div class="filters_dropdown_toggle"><div class="h5">'+title+'</div><svg class="filters_dropdown_icon" xmlns="http://www.w3.org/2000/svg" width="100%" viewbox="0 0 21 21" fill="none"><rect x="20.4995" y="0.5" width="20" height="20" rx="10" transform="rotate(90 20.4995 0.5)" stroke="#6E7886"></rect><path d="M6.99951 9.53564L10.535 13.0712L14.0706 9.53565" stroke="#6E7886"></path></svg></div>');
        if($(this).find('.tags-filter').length > 0){
            $('.filters_dropdown[data-id="'+fid+'"]').append('<div class="filters_dropdown_list" style="display: none;"><div class="filters_dropdown_list_inner"><div class="filters_dropdown_flex is--tags"></div></div></div>');
            $(this).find('.bapf_body ul li').each(function(){
                var cclass = '';
                if($(this).hasClass('checked')){
                    cclass = 'is--active';
                    vis = true;
                }
                $('.filters_dropdown[data-id="'+fid+'"] .filters_dropdown_flex').append('<a href="#" data-filter-id="'+$(this).find('input').attr('id')+'" class="filter_tag_link w-inline-block '+cclass+'"><div class="text_14px">'+$(this).find('label').html()+'</div></a>');
            });
            if(vis === true){
                $('.filters_dropdown[data-id="'+fid+'"]').find('.filters_dropdown_list').show();
            }
        }
        else{
            $('.filters_dropdown[data-id="'+fid+'"]').append('<div class="filters_dropdown_list" style="display: none;"><div class="filters_dropdown_list_inner"><div class="filters_dropdown_flex"></div></div></div>');
            $(this).find('.bapf_body ul li').each(function(){
                var cclass = '';
                if($(this).hasClass('checked')){
                    cclass = 'w--redirected-checked';
                    vis = true;
                }
                $('.filters_dropdown[data-id="'+fid+'"] .filters_dropdown_flex').append('<label data-filter-id="'+$(this).find('input').attr('id')+'" class="w-checkbox filters_checkbox_filed"><div class="'+cclass+' w-checkbox-input w-checkbox-input--inputType-custom filters_checkbox"></div><input type="checkbox" id="checkbox-4" name="checkbox-4" data-name="Checkbox 4" style="opacity:0;position:absolute;z-index:-1"><span class="text_14px is--filters-checkbox-label w-form-label" for="checkbox-4">'+$(this).find('label').html()+'</span></label>');
            });
            if(vis === true){
                $('.filters_dropdown[data-id="'+fid+'"]').find('.filters_dropdown_list').show();
            }
        }
    })
}

setTimeout(function(){
    $('body').on('click', '.filters_dropdown_toggle', function(){
        $(this).closest('.filters_dropdown').find('.filters_dropdown_list').slideToggle(500);
    });
    $('body').on('click', '[data-filter-id]', function(e){
        e.preventDefault();
        $('[for="'+$(this).attr('data-filter-id')+'"]').click();
        $(this).find('.w-checkbox-input').toggleClass('w--redirected-checked');
        var int = setInterval(function(){
            if($('.bapf_loader').length <= 0){
                clearInterval(int);
                populate_dwatro_filters();
            }
        }, 200)
    })
}, 2000)