$(document).ready(function(){
    if($('.is--basket-hero').length > 0){
        setInterval(function(){
            if($('#selected-paczkomat').length > 0){
                $('.inpost-details').remove();
                $('<div class="inpost-details">'+$('#selected-paczkomat').html()+'</div>').insertAfter('.open-inpost-map');
            }
            else{
                $('.inpost-details').remove();
            }
            console.log('seelctedpaczkomat')
        }, 1000)
    }
    if($('.basket-js').length > 0){

        $('body').on('click', '.woocommerce-remove-coupon', function(){
            $('.basket-js, .summary-js').addClass('loading');
            $(this).closest('.used-code').remove();
        });
        $('.shop_table .cart-discount').each(function(){
            var title = $(this).find('th').html();
            var content = $(this).find('td').html();
            $('<li class="basket_summary_list_item used-code"><div class="text_16px is--basket-summary-title">'+title+'</div><div class="text_16px is--basket-summary-desc">'+content+'</div></li>').insertAfter('.basket_summary_list_item.is--order');
        })
        if($('.woocommerce-cart-form__contents').length > 0){
            $('.woocommerce-cart-form__contents .cart_item').each(function(){
                var product_obj = $('.product-matrix > div');
                var img = $(this).find('.product-thumbnail img').attr('src').replace('-300x300', '');
                if($(this).find('.product-name a').length > 0) {
                    var name = $(this).find('.product-name a').html();
                }
                else{
                    var name = $(this).find('.product-name').html();
                }
                var price = $(this).find('.product-price').html();
                var line_total = $(this).find('.product-subtotal').html();
                var q = $(this).attr('data-quantity');
                product_obj.find('.product_cart_input').attr('value',q);
                product_obj.attr('data-cart-item', $(this).attr('data-cart-item'));
                var product = product_obj.prop('outerHTML');
                product = product.replace('PRODUCTIMG', '<img src="'+img+'" class="basket_item_image">');
                product = product.replace('PRODUCTNAME', name);
                product = product.replace('PRODUCTPRICE', price);
                product = product.replace('PRODUCTLINETOTAL', line_total);
                product = product.replace('PRODUCTREMOVE', $(this).find('.product-remove').html());


                $('.basket-js').append(product);


            })
        }
    }
})

setTimeout(function(){
    if($('.woocommerce-notices-wrapper').length > 0 && $('.notices-dwatro').length > 0){
        $('.woocommerce-notices-wrapper').appendTo('.notices-dwatro');
    }
    $('.basket-js .qchanger a').on('click', function(e){
        e.preventDefault();
        $('.basket-js, .summary-js').addClass('loading');
        var val = $(this).closest('.qchanger').find('input').val();
        if($(this).attr('fs-inputcounter-element') == 'decrement'){
            if(val > 0){
                val = val - 1;
            }
        }
        else{
            val = parseInt(val) + 1;
        }
        $(this).closest('.qchanger').find('input').val(val);
        $('tr[data-cart-item="'+$(this).closest('.basket_list_item').attr('data-cart-item')+'"]').find('input.qty')
            .val(val)
            .trigger('change');
        $('[name="update_cart"]').click();
        if(val == 0){
            $(this).closest('.basket_list_item').remove();
        }
        $(document.body).on('updated_cart_totals', function() {
            $('.basket-js, .summary-js').removeClass('loading');
        });
    });
    $(document.body).on('updated_cart_totals', function() {
        $('.basket-js, .summary-js').removeClass('loading');
        $('.basket_list_grid .basket_list_item').each(function(){
            var price = $(this).find('.h4.text_color_elegant_grey').text().trim();
            price = price.replace(',', '.').replace('zł', '').trim();
            var q = $(this).find('.product_cart_input').val();
            var total_price = price * q;
            total_price = number_format(total_price, 2, ',', ' ');
            $(this).find('.basket_item_column._3 .h4').html(total_price + ' zł');
        })
        $('.data-products').html($('#total-amount').attr('data-value'));
        var shipping = '';
        $('#shipping_method li').each(function(){
            if($(this).find('input').prop('checked') == true){
                $('.shipping-price').html($(this).attr('data-price'));
            }
        })
        $('.dwatro-cart-total').html($('.order-total').attr('data-value'));

        $('.used-code').remove();
        $('.shop_table .cart-discount').each(function(){
            var title = $(this).find('th').html();
            var content = $(this).find('td').html();
            $('<li class="basket_summary_list_item used-code"><div class="text_16px is--basket-summary-title">'+title+'</div><div class="text_16px is--basket-summary-desc">'+content+'</div></li>').insertAfter('.basket_summary_list_item.is--order');
        })

    });
    if($('.basket-js').length > 0){
        var shipping = '';
        $('#shipping_method li').each(function(){
            if($(this).find('input').prop('checked') == true){
                $('.shipping-price').html($(this).attr('data-price'));
            }
        })
    }

    function number_format (number, decimals, dec_point, thousands_sep) {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

}, 2000)