$(document).on('click', '.add-to-cart-ajax', function(e){
    e.preventDefault();
    var q = $(this).closest('.add-to-cart-container').find('#field-2').val();
    var pid = $(this).closest('.add-to-cart-container').find('#product-id').val();
    window.location.href = $('#current-url').val() + '?add-to-cart=' + pid + '&quantity=' + q;
});

$(document).on('click', '.dwatro-fav-toggle', function(e){
    e.preventDefault();
    var remove = false;
    if($(this).hasClass('remove-item')){
        remove = true;
    }
    dwatro_fav_toggle($(this).attr('data-pid'), remove);
});

$(document).on('click', '.short-fav-toggler', function(e){
    e.preventDefault();
    dwatro_short_fav_toggle($(this).attr('data-pid'), $(this));
});

setTimeout(function(){
    setFavFill();
    $('.single-product .product_input_flex [fs-inputcounter-element]').on('click', function(e){
        e.preventDefault();
        var inp = $(this).closest('.product_input_flex').find('input'), q;
        if(inp.val() == ''){
            q = 1;
        }
        else{
            q = parseInt(inp.val());
        }
        if($(this).attr('fs-inputcounter-element') == 'increment' || $(this).attr('fs-inputcounter-element') == 'increment-2'){
            q = q + 1;
        }
        else{
            if(q > 1){
                q = q - 1;
            }
            else{
                q = 1;
            }
        }
        inp.val(q);
    })
}, 500)

function setFavFill(){
    $('span[data-color]').each(function(){
        $(this).closest('.string-add').next('svg').find('path').attr('fill', $(this).attr('data-color'));
    })
}
function dwatro_fav_toggle(pid, remove){
    var int;
    $.ajax({
        url: ajax_obj.ajaxurl,
        method: 'post',
        data: {
            'action': 'dwatro_fav_toggle',
            'pid': pid,
            'nonce': ajax_obj.nonce
        },
        beforeSend: function (errorThrown) {
            $('.dwatro-fav-toggle[data-pid="'+pid+'"]').addClass('loading');
        },
        success: function (data) {
            $('.dwatro-fav-toggle[data-pid="'+pid+'"]').removeClass('loading');
            $('.dwatro-fav-toggle[data-pid="'+pid+'"] .string-add').html(data);
            if(remove){
                $('.dwatro-fav-toggle[data-pid="'+pid+'"]').closest('.remove-container').remove();
            }
            setFavFill();
        },
        error: function (errorThrown) {
            console.log(errorThrown);
        }
    });
}
function dwatro_short_fav_toggle(pid, th){
    var int;
    $.ajax({
        url: ajax_obj.ajaxurl,
        method: 'post',
        data: {
            'action': 'dwatro_short_fav_toggle',
            'pid': pid,
            'nonce': ajax_obj.nonce
        },
        beforeSend: function (errorThrown) {
            $('.short-fav-toggler[data-pid="'+pid+'"]').addClass('loading');
        },
        success: function (data) {
            $('.short-fav-toggler[data-pid="'+pid+'"]').removeClass('loading');
            $(data).insertAfter('.short-fav-toggler[data-pid="'+pid+'"]');
            th.remove();
        },
        error: function (errorThrown) {
            console.log(errorThrown);
        }
    });
}