$(document).ready(function(){
    if($('.basket-js').length > 0){
        if($('.woocommerce-cart-form__contents').length > 0){
            $('.woocommerce-cart-form__contents .cart_item').each(function(){
                var product_obj = $('.product-matrix > div');
                var img = $(this).find('.product-thumbnail img').attr('src').replace('-300x300', '');
                if($(this).find('.product-name a').length > 0) {
                    var name = $(this).find('.product-name a').html();
                }
                else{
                    var name = $(this).find('.product-name').html();
                }
                var price = $(this).find('.product-price').html();
                var line_total = $(this).find('.product-subtotal').html();
                var q = $(this).attr('data-quantity');
                product_obj.find('.product_cart_input').attr('value',q);
                product_obj.attr('data-cart-item', $(this).attr('data-cart-item'));
                var product = product_obj.prop('outerHTML');
                product = product.replace('PRODUCTIMG', '<img src="'+img+'" class="basket_item_image">');
                product = product.replace('PRODUCTNAME', name);
                product = product.replace('PRODUCTPRICE', price);
                product = product.replace('PRODUCTLINETOTAL', line_total);
                product = product.replace('PRODUCTREMOVE', $(this).find('.product-remove').html());


                $('.basket-js').append(product);


            })
        }
    }
})

setTimeout(function(){
    if($('.woocommerce-notices-wrapper').length > 0 && $('.notices-dwatro').length > 0){
        $('.woocommerce-notices-wrapper').appendTo('.notices-dwatro');
    }
    $('.basket-js .qchanger a').on('click', function(e){
        e.preventDefault();
        $('.basket-js, .summary-js').addClass('loading');
        var val = $(this).closest('.qchanger').find('input').val();
        if($(this).attr('fs-inputcounter-element') == 'decrement'){
            if(val > 0){
                val = val - 1;
            }
        }
        else{
            val = parseInt(val) + 1;
        }
        $(this).closest('.qchanger').find('input').val(val);
        $('tr[data-cart-item="'+$(this).closest('.basket_list_item').attr('data-cart-item')+'"]').find('input.qty')
            .val(val)
            .trigger('change');
        $('[name="update_cart"]').click();
        if(val == 0){
            $(this).closest('.basket_list_item').remove();
        }
        $(document.body).on('updated_cart_totals', function() {
            $('.basket-js, .summary-js').removeClass('loading');
        });
    });
    $(document.body).on('updated_cart_totals', function() {
        $('.data-products').html($('#total-amount').attr('data-value'));
        var shipping = '';
        $('#shipping_method li').each(function(){
            if($(this).find('input').prop('checked') == true){
                $('.shipping-price').html($(this).attr('data-price'));
            }
        })
        $('.dwatro-cart-total').html($('.order-total').attr('data-value'));
    });
    if($('.basket-js').length > 0){
        var shipping = '';
        $('#shipping_method li').each(function(){
            if($(this).find('input').prop('checked') == true){
                $('.shipping-price').html($(this).attr('data-price'));
            }
        })
    }

}, 2000)