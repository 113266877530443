setTimeout(function(){
    $('.op_radio_grid .form_radio_field').on('click', function(){
        $('#kupno-field').val($(this).find('.is--radio-label').html());
    })

    $('.form_radio_field').on('click', function(){
        $('.w-radio-input').removeClass('w--redirected-checked');
        $(this).find('.w-radio-input').addClass('w--redirected-checked');
    })
    $(document).on('click', '.checkbox_field', function(){
        $(this).find('.w-checkbox-input').toggleClass('w--redirected-checked');
    })
    $('.button_cv').on('click', function(){
        $(this).closest('.careers_form_button_wrap').find('[data-name="file-35"] input').click();
    })
}, 2000)
