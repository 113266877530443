$(document).ready(function(){
    get_dwatro_cart_count();

    function get_dwatro_cart_count(){
        $.ajax({
            url: ajax_obj.ajaxurl,
            method: 'post',
            data: {
                'action': 'get_dwatro_cart_count',
                'nonce': ajax_obj.nonce
            },
            success: function (data) {
                $('#dwatro-cart-total').html(data);
            },
            error: function (errorThrown) {
                console.log(errorThrown);
            }
        });
    }
})