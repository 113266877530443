setTimeout(function(){
    $('body').on('change', '[data-ac-checkout="1"]', function(){
        $('#' + $(this).attr('data-ac-field'))
            .val($(this).val())
            .trigger('change');
    });
    $('body').on('change', '[data-ac-field="billing_address_1"], [data-ac-field="billing_house_number"], [data-ac-field="billing_apartment_number"]', function(){
        var val = $('[data-ac-field="billing_address_1"]').val() + ' ' + $('[data-ac-field="billing_house_number"]').val();
        if($('[data-ac-field="billing_apartment_number"]').val() != ''){
            val = val + ' m. ' + $('[data-ac-field="billing_apartment_number"]').val();
        }
        $('#billing_address_1').val(val)
            .trigger('change');
    });
    if($('[data-ac-field="billing_address_1"]').length > 0){
        $('[data-ac-field="billing_address_1"]').trigger('change')
        $('[data-ac-field="shipping_address_1"]').trigger('change')
    }
    $('body').on('change', '[data-ac-field="shipping_address_1"], [data-ac-field="shipping_house_number"], [data-ac-field="shipping_apartment_number"]', function(){
        var val = $('[data-ac-field="shipping_address_1"]').val() + ' ' + $('[data-ac-field="shipping_house_number"]').val();
        if($('[data-ac-field="shipping_apartment_number"]').val() != ''){
            val = val + ' m. ' + $('[data-ac-field="shipping_apartment_number"]').val();
        }
        $('#shipping_address_1').val(val)
            .trigger('change');
    });
    $(document).on('click', '.show-company', function(){
        $('.company-toggle').show();
        $('.company-toggle').find('input').attr('required', 'required');
    })
    $(document).on('click', '.hide-company', function(){
        $('.company-toggle').hide();
        $('.company-toggle').find('input').val('');
        $('.company-toggle').find('input').removeAttr('required');
        $('.company-toggle').find('input').trigger('change');
    })
    $('.shipping-address-toggler').on('click', function(){
        $('#ship-to-different-address-checkbox').prop('checked', $(this).find('input').prop('checked'));
        $('#ship-to-different-address-checkbox').trigger('change');
        if($(this).find('input').prop('checked') == true){
            $('.shipping-form').css('display', 'grid');
        }
        else{
                $('.shipping-form').css('display', 'none');

        }
        var th = $(this);
        setTimeout(function(){
            if(th.find('.w--redirected-checked').length > 0){
            }
            else{
            }
        }, 200)
    })
    $(document).on('click', '.open-inpost-map', function(e){
        e.preventDefault();
        $('.select-paczkomat-button').click();
    })

    $(document).on('keyup', '.cupon-field', function(){
        $('#coupon_code').val($(this).val());
    })
    $(document).on('click', '.dwatro-apply-coupon', function(){
        $('[name="apply_coupon"]').click();
    })



    $('body').on('click', '[name="Payment-Option"]', function(){
        $('.wc_payment_methods input[value="'+$(this).val()+'"]').click();
        $('.wc_payment_methods input[value="'+$(this).val()+'"]').trigger('change');
    })
    $('body').on('submit', '#dwatro-checkout', function(e){
        e.preventDefault();
        $('.woocommerce-checkout').submit();
    })
    // $('.is--summary-order').on('click', function(e){
    //     e.preventDefault();
    //     $('#place_order').click();
    // })

    $('body').on('click', '.change-woo-delivery', function(){
        if($(this).find('input').prop('checked') == false){
            $('.open-inpost-map').remove();
            $('#shipping_method li[data-index="'+$(this).attr('data-index')+'"]')
                .find('input')
                .prop('checked', true)
                .trigger('change');
        }
    })
    $(document.body).on('update_checkout', function() {
        $('.order_summary_form_block').addClass('loading');
        $('.is--delivery-partner').html('');
        $('.is--payment').html('');
    });
    if($('.is--delivery-partner').length > 0){
        var checked = false;

        $('#shipping_method li').each(function(){
            if($(this).find('input').prop('checked') == true){
                checked = true;
            }
            else{
                checked = false;
            }
            $('.is--delivery-partner').append('<label class="summary_radio_field w-radio change-woo-delivery" data-index="'+$(this).attr('data-index')+'">\n' +
                '                                <div class="w-form-formradioinput w-form-formradioinput--inputType-custom summary_radio_button w-radio-input '+(checked?'w--redirected-checked':'')+'"></div>\n' +
                '                                <input type="radio" '+(checked?'checked="checked"':'')+' name="Delivery-Partner" data-name="Delivery Partner" required="" style="opacity:0;position:absolute;z-index:-1" value="Przesyłka kurierska (Inpost) - Polska: 11,99 zł"><span class="text_14px is--radio-label w-form-label" for="Przesy-ka-kurierska-Inpost---Polska-11-99-z">'+$(this).attr('data-name')+': '+$(this).attr('data-price')+'</span>\n' +
                '                            </label>');
        })
    }
    if($('.is--payment').length > 0){
        var img, checked = false;
        $('.wc_payment_methods li').each(function(){
            if($(this).find('input').prop('checked') == true){
                checked = true;
            }
            else{
                checked = false;
            }
            if($(this).find('img').length > 0){
                img = $(this).find('img').attr('src');
            }
            else{
                img = $('.payment-icons .' + $(this).find('input').val()).find('img').attr('src');
            }
            $('.is--payment').append('<label class="summary_radio_field w-radio">\n' +
                '                                <div class="w-form-formradioinput w-form-formradioinput--inputType-custom summary_radio_button w-radio-input '+(checked?'w--redirected-checked':'')+'"></div><input type="radio" '+(checked?'checked="checked"':'')+' name="Payment-Option" id="PayU" value="'+$(this).find('input').val()+'" data-name="Payment Option" required="" style="opacity:0;position:absolute;z-index:-1" value="PayU"><img src="'+img+'" loading="lazy" alt="" class="payment_option_logo"><span class="text_14px is--radio-label is--hidden w-form-label" for="PayU">Przesyłka kurierska (Inpost) - Polska: 11,99 zł</span>\n' +
                '                            </label>');
        })
    }

    $(document.body).on('updated_checkout', function() {
        var order_total = $('.order-total').attr('data-val');
        var price = '0.00 zł';
        $('#shipping_method li').each(function(){
            if($(this).find('input').prop('checked') == true){
                price = $(this).attr('data-price');
            }
        })
        $('.shipping-total').html(price);
        $('.order-total-price').html(order_total);
        $('.order_summary_form_block').removeClass('loading');
        if($('.is--delivery-partner').html() == '') {
            if ($('.is--delivery-partner').length > 0) {
                var checked = false;

                $('#shipping_method li').each(function () {
                    if ($(this).find('input').prop('checked') == true) {
                        checked = true;
                    } else {
                        checked = false;
                    }
                    $('.is--delivery-partner').append('<label class="summary_radio_field w-radio change-woo-delivery" data-index="' + $(this).attr('data-index') + '">\n' +
                        '                                <div class="w-form-formradioinput w-form-formradioinput--inputType-custom summary_radio_button w-radio-input ' + (checked ? 'w--redirected-checked' : '') + '"></div>\n' +
                        '                                <input type="radio" ' + (checked ? 'checked="checked"' : '') + ' name="Delivery-Partner" data-name="Delivery Partner" required="" style="opacity:0;position:absolute;z-index:-1" value="Przesyłka kurierska (Inpost) - Polska: 11,99 zł"><span class="text_14px is--radio-label w-form-label" for="Przesy-ka-kurierska-Inpost---Polska-11-99-z">' + $(this).attr('data-name') + ': ' + $(this).attr('data-price') + '</span>\n' +
                        '                            </label>');
                })
            }
            if ($('.is--payment').length > 0) {
                var img;
                $('.wc_payment_methods li').each(function () {
                    if ($(this).find('img').length > 0) {
                        img = $(this).find('img').attr('src');
                    } else {
                        img = $('.payment-icons .' + $(this).find('input').val()).find('img').attr('src');
                    }
                    $('.is--payment').append('<label class="summary_radio_field w-radio">\n' +
                        '                                <div class="w-form-formradioinput w-form-formradioinput--inputType-custom summary_radio_button w-radio-input"></div><input type="radio" name="Payment-Option" id="PayU" value="' + $(this).find('input').val() + '" data-name="Payment Option" required="" style="opacity:0;position:absolute;z-index:-1" value="PayU"><img src="' + img + '" loading="lazy" alt="" class="payment_option_logo"><span class="text_14px is--radio-label is--hidden w-form-label" for="PayU">Przesyłka kurierska (Inpost) - Polska: 11,99 zł</span>\n' +
                        '                            </label>');
                })
            }
        }
        $('#shipping_method li').each(function(){
            if($(this).find('label').next('div').length > 0){
                $('.is--delivery-partner label[data-index="'+$(this).attr('data-index')+'"]').append('<a href="#" class="open-inpost-map">'+$(this).find('button').html()+'</a>');
            }
        })
    });

    if($('.shipping-total').length > 0){
        var price = '0.00 zł';
        $('#shipping_method li').each(function(){
            if($(this).find('input').prop('checked') == true){
                price = $(this).attr('data-price');
            }
        })
        $('.shipping-total').html(price);
    }
    jQuery(document.body).on('checkout_error', function(event, error_message) {
        $('.notices-dwatro').html('<div class="woocommerce-NoticeGroup woocommerce-NoticeGroup-checkout">'+error_message+'</div>');
        // console.log('Checkout failed:', error_message);
        // Dodatkowe akcje po nieudanym procesie checkout
    });
}, 2000)

// woocommerce-NoticeGroup-checkout