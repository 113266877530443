setTimeout(function(){
    $('.copy-link').click(function(){
        var textToCopy = $(this).attr('data-url');
        var tempTextArea = $('<textarea>');
        tempTextArea.val(textToCopy);
        $('body').append(tempTextArea);
        tempTextArea.select();
        document.execCommand('copy');
        tempTextArea.remove();
        alert('Skopiowano do schowka');
    });
}, 1000)